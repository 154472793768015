import React from "react";
// External libraries
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types";
import { I18nextProvider, useTranslation } from 'gatsby-plugin-react-i18next';
// Common components
import Navbar from "../Navbar";
import Footer from "../Footer";
import WhatsAppFloater from "../WhatsAppFloater";

function PageLayout(props) {
  const { children } = props;
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <div style={{ paddingTop: 80 }} />
      <Navbar />
      <main>
        {children}
      </main>
      <WhatsAppFloater />
      <Footer />
    </>
  );
}

PageLayout.propTypes = {
  // children: PropTypes.element,
};

export default PageLayout;
