/* eslint-disable react/jsx-fragments */
import React, { Fragment } from "react";
// External Libraries
// import { useStore } from "react-context-hook";
import { useTranslation } from "gatsby-plugin-react-i18next";
// Custom components
import ContactTabDetails from "../constants/ContactTabDetails";

export default () => {
  const { t } = useTranslation();
  let data = ContactTabDetails["generalWhatsapp"];

  return (
    <Fragment>
      {data && (
        <ul className="navbar-nav navbarmenu">
          <li className="nav-item px-2 border-left">
            <div className="row">
              <div className="col">
                <div className="m-0" style={{ width: 210, alignItems:"center", flexDirection:"row", display:"flex" }}>
                  <div>
                    <img
                      src={data.imageUrl}
                      style={{ width: "30px", height: "30px" }}
                      alt={data.imageAlt}
                    />
                  </div>
                  <div>
                    <a
                      className="nav-link"
                      href={data.contactLink}
                      style={{
                        color: "#3b3a3b",
                        fontSize: 12,
                        fontFamily: "lato",
                      }}
                    >
                      {t(data.text1)}
                      <br />
                      <strong className="strong3-yellow">{t(data.text2)}</strong>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      )}
    </Fragment>
  );
};
