export default {
    generalEmail: {
        contactLink: "mailTo:support@disinfection2u.com",
        text1: "NAVBAR.emailUs",
        imageUrl: require("../../../images/telephone-darkgrey.png"),
        imageAlt: "Email",
        text2: "NAVBAR.general",
    },
    generalWhatsapp: {
        contactLink: "https://wa.me/6281215511551?text=I'd like to know more about booking a Professional Disinfection from Disinfection2U",
        text1: "NAVBAR.whatsapp247",
        imageUrl: require("../../../images/whatsapp2.svg"),
        imageAlt: "NAVBAR.WhatsApp",
        text2: "NAVBAR.whatsappNumber",
    },
};
