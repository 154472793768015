export default [
  {
    text: "intro",
    link: "#intro",
  },
  {
    text: "pricesOptions",
    link: "#prices",
  },
  {
    text: "theProcess",
    link: "#process",
  },
  {
    text: "whyBuy",
    link: "#why",
  },
  {
    text: "faq",
    link: "#faq",
  },
  {
    text: "contactUs",
    link: "#contact",
  },
];
