/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import $ from "jquery";
import FooterDetails from "./constants/FooterDetails.js";
import { useTranslation } from "gatsby-plugin-react-i18next";

export default (props) => {
  const { t } = useTranslation();
  const data = FooterDetails["default"];
  const brandLogo = require("../../images/Disinfection-logo.png");
  const locationIcon = require("../../images/location.png");
  const phoneIcon = require("../../images/phone.png");
  const igIcon = require("../../images/ig-logo.png");
  const emailIcon = require("../../images/email.png");
  const handleQuoteRequest = () => $("#getQuoteModal").modal("show");

  return (
    <div className="footerNew">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="row py-3">
              <img
                src={brandLogo}
                style={{ maxWidth: 160, height: "100%", width: "100%" }}
                alt="ServisHero"
              />
              <div
                className="mx-1 mx-md-3"
                style={{ backgroundColor: "#333333", width: 2 }}
              />
              <div className="ml-3" style={{ maxWidth: 180 }}>
                <p
                  className="my-auto"
                  style={{
                    fontFamily: "Lato",
                    fontWeight: 600,
                    fontSize: 17,
                    lineHeight: 1.5,
                  }}
                >
                  {t("FOOTER.footerTagline")}
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4 py-3 py-md-3 ml-auto my-auto">
            <button
              type="button"
              className="btn btn-primary btn-block CTA CTA-secondary"
              onClick={handleQuoteRequest}
              style={{ fontSize: 16, fontWeight: "bold", fontFamily: "Lato" }}
            >
              {t("FOOTER.footerRequest")}
            </button>
          </div>
        </div>

        <hr />

        {/* CONTACT INFO SECTION */}
        <div className="row" style={{ marginBottom: 16 }}>
          <div className="col-md-6">
            <p style={{ fontSize: 24, fontWeight: 600 }}>
              {t("FOOTER.footerContactUs")}
            </p>
            <div className="row">
              {/* <div className="col-md-6 col-sm-12">
                <div className="row">
                  <div className="col-1">
                    <img src={locationIcon} alt="location" />
                  </div>
                  <div className="col-10">
                    <p
                      style={{
                        fontSize: 16,
                        fontWeight: 500,
                        whiteSpace: "pre-line",
                      }}
                    >
                      {t(data.companyAddress)}
                    </p>
                  </div>
                </div>
              </div> */}
              <div className="col-md-6 col-sm-12">
                <div className="row">
                  <div className="col-1">
                    <img src={phoneIcon} alt="phone" />
                  </div>
                  <div className="col-10">
                    <p style={{ fontSize: 16, fontWeight: 500 }}>
                      {t(data.customerCareNumber)}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-1">
                    <img src={emailIcon} alt="email" />
                  </div>
                  <div className="col-10">
                    <p style={{ fontSize: 16, fontWeight: 500 }}>
                      {t(data.email)}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-1">
                    <a
                      href="https://www.instagram.com/disinfection2u"
                      className="ml-auto flex-end"
                    >
                      <img src={igIcon} alt="ig" width="22px" height="22px" />
                    </a>
                  </div>
                  <div className="col-10">
                    <p style={{ fontSize: 16, fontWeight: 500 }}>
                      <a href="https://www.instagram.com/disinfection2u" style={{color:'black'}}>@disinfection2u</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*
          <div className="col-md-2 ml-auto justify-content-end">
            <a href={t(data.companyUrl)} className="ml-auto flex-end">
              <img
                src={t(data.companyLogo)}
                alt={t(data.companyLogoAlt)}
                style={{ width: "100%" }}
              />
            </a>
          </div>
          */}
        </div>
      </div>

      {/* COPYRIGHT STRIP */}
      <div
        className="text-center p-1"
        style={{
          backgroundColor: "#BDBDBD",
          color: "#4F4F4F",
          fontSize: 14,
          fontFamily: "Lato",
        }}
      >
        {t(data.copyright)}
      </div>
    </div>
  );
};
