import TH from "./TH";
import defaultInfo from "./defaultInfo";
import advancedCoating from "./advancedCoating";

export default {
  TH: [...TH],
  SG: [...defaultInfo],
  MY: [...defaultInfo],
  AU: [...defaultInfo],
  NZ: [...defaultInfo],
  advancedCoating: [...advancedCoating],
  default: [...defaultInfo],
};
