import React, { Fragment } from "react";
// External Libraries
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { whatsappUrl } from "../../constants/ContactUrl";

export default (props) => {
  const { theLorry } = props;
  return (
    <Fragment>
      {!!whatsappUrl && (
        <div>
          <a href={whatsappUrl} className="float" target="_blank">
            {/* <i className="fa fa-whatsapp my-float" /> */}

            <FontAwesomeIcon icon={faWhatsapp} className="my-float" />
            <p className="float-text">
              {"Talk to us?"}
            </p>
          </a>
        </div>
      )}
    </Fragment>
  );
};
