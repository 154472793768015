export default [
  {
    text: "intro",
    link: "#intro",
  },
  {
    text: "ourServices",
    link: "#services",
  },
  {
    text: "ourPrices",
    link: "#prices",
  },
  {
    text: "whyUs",
    link: "#why",
  },
  {
    text: "clients",
    link: "#clients",
  },
  {
    text: "contactUs",
    link: "#contact",
  },
];
