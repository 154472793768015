import React from "react";
// External Libraries
import NavigationLink from "./components/NavigationLink";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import $ from "jquery";
import { Link, useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import ContactTab from "./components/ContactTab";

function NavigationBar(props) {
  const { t } = useTranslation();
  const { languages, changeLanguage, language, originalPath } = useI18next();
  const brandLogo = require("../../images/Disinfection-logo.png");
  const handleQuoteRequest = () => $("#getQuoteModal").modal("show");
  const isEn = language === "en";
  const changeTranslation = (e) => {
    e.preventDefault();
    isEn ? changeLanguage("id") : changeLanguage("en");
  };

  return (
    <nav
      className="navbar navbar-expand-lg navbar-light mb-5 fixed-top"
      id="myNewHeader"
    >
      <div className="container">
        <Link className="navbar-brand" to="/" language={language}>
          <img src={brandLogo} style={{ maxWidth: 180 }} alt="ServisHero" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarsExample04"
          aria-controls="navbarsExample04"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div
          className="collapse navbar-collapse navbarmenu"
          id="navbarsExample04"
          style={{ position: "relative", right: 0 }}
        >
          <ul className="navbar-nav navbarmenu pr-4 ml-auto">
            <li className="nav-item px-2">
              <a
                className="nav-link"
                href="#"
                onClick={handleQuoteRequest}
                style={{
                  fontFamily: "Lato",
                  fontWeight: "bold",
                  color: "#333333",
                }}
              >
                {t("NAVBAR.quoteRequest")}
              </a>
            </li>
            {/* <li className="nav-item px-2">
              <a
                className="nav-link"
                href="#"
                style={{
                  fontFamily: "Lato",
                  fontWeight: "bold",
                  color: "#333333",
                }}
              >
                SAFE SPACES 101
              </a>
            </li> */}
          </ul>
          <ContactTab />
          <a
            className="btn btn-outline-secondary btn-sm shadow-sm"
            type="button"
            href="#"
            onClick={changeTranslation}
          >
            {isEn ? "Bahasa Indonesia" : "English"}
          </a>
        </div>
      </div>
    </nav>
  );
}

export default NavigationBar;
